@import "../../../../../styles/variables";

.bold {
  font-weight: 900;
}

.banner {
  min-height:      100vh;
  position:        relative;
  z-index:         1;
  padding:         90px 0 0 0;
  display:         flex;
  flex-direction:  column;
  justify-content: space-between;
  overflow:        hidden;
  &:before {
    content:    '';
    position:   fixed;
    z-index:    1;
    left:       0;
    top:        0;
    width:      100%;
    height:     100%;
    background: black;
    opacity:    .4;
  }
  h1 {
    color:    white;
    position: relative;
    z-index:  3;
  }
  .background {
    height:     100%;
    width:      100%;
    position:   fixed;
    z-index:    -1;
    top:        0;
    left:       0;
    object-fit: cover;
  }
  .content {
    position:        relative;
    z-index:         2;
    display:         flex;
    align-items:     flex-start;
    justify-content: space-between;
    margin-top:      -4px;
    p {
      color: white;
    }
  }
  .titleBottom {
    display:     flex;
    align-items: flex-start;
  }
  .drop {
    color:         $yellow;
    font-size:     100px;
    line-height:   0.8;
    font-weight:   200;
    margin-bottom: 0;
    height:        0;
    font-family:   'Libre Franklin', sans-serif;
    margin-top:    -5px;
  }
  .black {
    white-space:   nowrap;
    margin-left:   auto;
    margin-top:    0;
    color:         white;
    display:       inline-block;
    font-size:     50px;
    font-weight:   300;
    position:      relative;
    z-index:       -1;
    margin-bottom: 14px;
    padding:       0 10px;
    &:after {
      content:          '';
      position:         absolute;
      z-index:          -1;
      bottom:           14%;
      left:             0;
      width:            100%;
      height:           90%;
      background-color: black;
    }
  }
  .left {
    margin-top: 72px;
    width:      48%;
    button {
      margin-top:    10px;
      margin-bottom: 34px;
    }
    p {
      span {
        font-weight: 900;
        color:       $yellow;
      }
    }
  }
  .right {
    display:        flex;
    flex-direction: column;
    position:       relative;
    z-index:        2;
    align-items:    flex-end;
    p {
      display:       inline-block;
      font-size:     50px;
      font-weight:   300;
      position:      relative;
      z-index:       2;
      margin-bottom: 14px;
      &.yellow {
        font-size:     30px;
        font-weight:   900;
        color:         black;
        margin-bottom: 2px;
        padding-right: 40px;
        &:after {
          content:          '';
          position:         absolute;
          z-index:          -1;
          bottom:           14%;
          left:             0;
          width:            100%;
          height:           90%;
          background-color: $yellow;
        }
      }
    }
    .timer {
      display: inline-block;
      .play {
        display: block;
      }
    }
    .play {
      width:       68px;
      cursor:      pointer;
      transition:  .3s linear;
      margin-left: auto;
      display:     none;
      &:hover, &:active {
        opacity: .7;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .banner {
    padding: 57px 0 0 0;
    &:before {
      opacity: .6;
    }
    h1 {
      display: inline-block;
    }
    .drop {
      font-size: 50px;
    }
    .black {
      font-size:      24px;
      letter-spacing: -1px;
      padding:        0;
      &:after {
        bottom: -10%;
        left:   -5%;
        width:  110%;
        height: 120%;
      }
    }
    .titleBottom {
      margin-top: 6px;
    }
    .content {
      margin-top: -126px;
    }
    .right {
      text-align: right;
      .play {
        width:         39px;
        margin-bottom: 26px;
        display:       block;
      }
      .timer {
        .play {
          display: none;
        }
      }
      p {
        font-size:     40px;
        margin-bottom: 10px;
        &.yellow {
          font-size:      24px;
          letter-spacing: -1px;
          padding-right:  0;
          &:after {
            height: 108%;
          }
        }
      }
    }
    .left {
      margin-top: 150px;
      width:      56%;
    }
  }
}

@media screen and (max-width: 768px) {
  .banner {
    .content {
      flex-direction: column-reverse;
      margin-top:     0;
    }
    .black {
      margin-bottom: 52px;
    }
    .left {
      margin-top: 0;
      width:      100%;
      p {
        line-height: 1.1;
      }
    }
    .right {
      width:         100%;
      max-width:     none;
      margin-bottom: 96px;
      .play {
        display: none;
      }
      .timer {
        p {
          margin-bottom: 18px;
        }
        .play {
          display: block;
        }
      }
    }
  }
}